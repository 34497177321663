import { IAccountCondensed } from "../../../Types/account";
import { ActionRedux } from "../../../Types/actionRedux";
import * as types from './accountConstants';


const initialState = {
  loading: true,
  accounts: [] as IAccountCondensed[]
}

export const accountReducer = (state = initialState, action: ActionRedux) => {
  const { type, payload} = action;
  switch (type) {
    case types.SET_ACCOUNTS:
      return {
        ...state,
        loading: false,
        accounts: payload
      };
    case types.ADD_ACCOUNT:
      return {
        ...state,
        loading: false,
        accounts: [...state.accounts, payload]
      }
    case types.REMOVE_ACCOUNT:
      return {
        ...state,
        loading: false,
        accounts: state.accounts.filter(account => account.id !== payload)
        };
    default:
      return state;
  }
};
