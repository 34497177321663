import axios from "axios";
import { IUser } from "../Types/user";

export const postLogin = (username: string, password: string, callback: (response: IUser) => void, errorCallback) => {
    const URL = `https://intenti-co.services/login`;
    // Create a FormData object and append username and password
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return axios(URL, {
      method: "POST",
      data: formData
    })
      .then((response) => {
        if (callback != null) {
          callback(response.data as IUser);
        }
      })
      .catch((error) => {
        // catch error
        if (errorCallback != null) {
          errorCallback(error);
        }
      });
  };