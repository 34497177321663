import * as types from "./authConstants"
import { produce } from 'immer';
import { IUser, USER_ROLE } from "../../Types/user";
import { ActionRedux } from "../../Types/actionRedux";

let userType: IUser = {
  uid: 0,
  username: '',
  role: USER_ROLE.USER,
  name: null,
  email: null,
  full_name: null,
  access_token: '',
  client_id: null,
  client: null
};

const initialState = {
  loading: true,
  isAuthenticated: false,
  token: null,
  user: userType,
  formSubmitting: false
};

export const authReducer = (state = initialState, action: ActionRedux) =>
  produce(state, draft => {
    switch (action.type) {
      case types.USER_LOADED:
      case types.LOGIN_SUCCESS:
      case types.REGISTER_SUCCESS:
        localStorage.setItem('user', JSON.stringify(action.payload));
        draft.isAuthenticated = true;
        draft.loading = false;
        draft.token = action.payload.access_token;
        draft.user = action.payload;
        break;
      case types.LOGIN_FAILED:
      case types.AUTH_ERROR:
      case types.REGISTER_FAILED:
        localStorage.removeItem('user');
        draft.token = null;
        draft.isAuthenticated = false;
        draft.loading = false;
        break;
      case types.LOGOUT:
        localStorage.removeItem('user');
        draft.token = null;
        draft.user= userType
        draft.isAuthenticated = false;
        draft.loading = false;
        break;
      case 'SET_FORMIK_SUBMITTING':
        draft.formSubmitting = action.payload; 
        break;

      default:
        return state;
    }
  });
