import { combineReducers } from 'redux';
import { authReducer } from '../Components/Auth/authReducer'
import { alertReducer } from '../Components/Alert/alertReducer';
import { accountReducer } from '../Components/Account/redux/AccountReducer';

export const RootReducer = combineReducers({
    auth: authReducer,
    alerts: alertReducer,
    accounts: accountReducer
});
