export interface IUser {
    uid: number;
    username: string;
    role: USER_ROLE;
    name: string | null;
    email: string | null;
    full_name: string | null;
    access_token: string | null;
    client_id: number | null;
    client: IClient | null
}

export interface IUserCondensed {
    uid: string;
    full_name: string 
}

export interface IClient {
    name: string;
    domain: string;
    address: string | null;
    privacy_policy: string | null;
    client_type_id: number;
    id: number;
    industry_id: number | null;
}

export interface IReqLogin {
    username: string;
    password: string;
    email?: string;
}

export interface DispatchAuth {
    type: string;
    payload?: any;
}

export enum USER_ROLE {
    ADMIN = 'Admin',
    USER = 'User',
    SALES_REP = 'Sales Rep',
    SALES_LEADER = 'Sales Leader'
}

export interface IToken {
    access_token: string,
    token_type: string
}
  