import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import AccessDenied from '../Pages/Error/AccessDenied'
import { PATH } from '../Constants/paths'
import { USER_ROLE } from '../Types/user'
import { getUserFromLS } from '../Util/getUserFromLS'

interface Props {
  component: React.ComponentType
  path?: string
  roles: Array<USER_ROLE>
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
  const user = getUserFromLS()
  const userHasRequiredRole = user && roles.includes(user.role) ? true : false

  if (user.role === undefined) {
    return <Navigate to={PATH.LOGIN}/>
  }

  if (userHasRequiredRole) {
    return <RouteComponent />
  }

  return <AccessDenied />
}