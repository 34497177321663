import * as actions from './alertActions';
import { v4 as uuid } from 'uuid';
import { Dispatch } from 'redux';
import { AlertTypes } from '../../Types/alertRelated';

interface PayloadAlert {
  msg: string;
  type: AlertTypes;
}
export const setAlert = (payload: PayloadAlert) => async (dispatch: Dispatch<any>)  => {
  const newAlert = { ...payload, id: uuid() };
  dispatch(actions.setAlertSuccess(newAlert));

  setTimeout(() => dispatch(actions.removeAlertSuccess(newAlert.id)), 4000);
};

