import * as actions from './authActions';
import { Dispatch } from 'redux';
import { setAlert } from '../Alert/alertThunks';
import { AlertTypes } from '../../Constants/alerts';
import { IReqLogin } from '../../Types/user';
import { postLogin } from "../../Api/postLogin";
import { getUser } from '../../Api/getUser';
import { getUserFromLS } from '../../Util/getUserFromLS';
import TagManager from 'react-gtm-module'


export const loadUser = () => async (dispatch: Dispatch<any>) => {
  const user = getUserFromLS()

  if (!user.uid) {
    dispatch(actions.authError());
    dispatch(
      setAlert({ msg: 'Cant not load user!', type: AlertTypes.ERROR })
    )
    return;
  }
  try {
    getUser(
      (res) => {
        dispatch(actions.userLoaded(res))
      },
      (err) => {
        dispatch(actions.authError());
      }
    )
  } catch (error) {
    dispatch(actions.authError());
    if (error instanceof Error){
      dispatch(
        setAlert({ msg: error.message, type: AlertTypes.ERROR })
      )
    }
    return;
  }
};

export const login = (payload: IReqLogin) => async (dispatch: Dispatch<any>) => {
  const { username, password } = payload;
  try {
    postLogin(
      username,
      password,
      (res) => {
        const tagManagerArgs = {
          dataLayer: {
              event: 'LoginEvent',
              timeStamp: new Date().toISOString(), // current date time,
              email: res.email,
              name: res.full_name,
              role: res.role,
              client: res.client,
              uid: res.uid
          }
        }
        TagManager.dataLayer(tagManagerArgs);

        dispatch(actions.loginSuccess(res));
        dispatch(
          setAlert({
            msg: 'You are logged in!',
            type: AlertTypes.SUCCESS,
          })
        )
      },
      (err) => {
        dispatch(
          setAlert({
            msg: 'Invalid credentials',
            type: AlertTypes.ERROR,
          })
        )    
        dispatch(actions.loginFailed())
        dispatch(actions.setFormikSubmitting(false));
      }
    )
  } catch (error) {
    dispatch(
      setAlert({
        msg: 'Invalid credentials',
        type: AlertTypes.ERROR,
      })
    )
    return dispatch(actions.loginFailed());
  }
};

export const logout = () => async (dispatch: Dispatch<any>) => {
  dispatch(actions.logoutSuccess());
  dispatch(
    setAlert({
      msg: 'You are logged out!',
      type: AlertTypes.WARNING,
    })
  )
};
