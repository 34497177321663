import React from 'react';
import { NoPerms } from '../../Components/Error/noPerms';
import TagManager from 'react-gtm-module'
import { getUserFromLS } from '../../Util/getUserFromLS';

const _AccessDeniedPage = () => {
  const user = getUserFromLS();
  const tagManagerArgs = {
    dataLayer: {
        event: 'PageViewEvent',
        page: 'AccessDeniedPage',
        timeStamp: new Date().toISOString(), // current date time,
        email: user.email,
        name: user.full_name,
        role: user.role,
        client: user.client,
        uid: user.uid
    }
  }
  TagManager.dataLayer(tagManagerArgs);

  return (
      <NoPerms />
  );
};
const AccessDeniedPage = React.memo(_AccessDeniedPage);
export { AccessDeniedPage };
export default AccessDeniedPage;
