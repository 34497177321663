import { useNavigate } from 'react-router-dom';

export const NoPerms = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <main id="tm-main">
      <div className="uk-section-muted uk-section uk-section-small" uk-height-viewport="expand: true;">
        <div className="uk-container uk-container-large">
          <div className="uk-grid uk-flex uk-flex-middle" uk-grid>
            <div className="uk-width-1-1">
              <h1 className="uk-heading-medium uk-text-center">Access Denied</h1>
              <p className="uk-text-center uk-text-lead">
                You do not have permission to view this page. Please log in or contact your system administrator if you think this is a mistake.
              </p>
              <div className="uk-text-center uk-margin-top">
                <button 
                  className="uk-button uk-button-primary uk-button-large"
                  onClick={goBack}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};