import * as types from './alertConstants';
import { IAlert } from '../../Types/alertRelated';
import { ActionRedux } from '../../Types/actionRedux';

const initialState = [] as IAlert[];

export const alertReducer = (state = initialState, action: ActionRedux) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALERT:
      return [...state, payload];
    case types.REMOVE_ALERT:
      return state.filter(alert => alert.id !== payload);
    default:
      return state;
  }
};
