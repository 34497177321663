export const PATH = {
    // Auth Pages
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',

    // Error Pages
    ACCESS_DENIED: '/access-denied',
    NOT_FOUND: '/not-found',
    
    // Account Pages
    UPDATE_ACCOUNT: "/all-accounts",
    ACCOUNT: '/account/:id',

    // Industry Insight Pages
    INDUSTRY_INSIGHTS: "/industry-insights",

    // Static Pages
    LANDING: '/'

};