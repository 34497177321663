import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Store";
import TagManager from 'react-gtm-module'
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

const tagManagerArgs = {
  gtmId: 'GTM-TBW77W85'
}

TagManager.initialize(tagManagerArgs)
UIkit.use(Icons); // Initialize UIkit icons

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense>
      <Provider store={store}>
          <App></App>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
